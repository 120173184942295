h2 {
  margin-top: 30px;
}
ol.term {
  padding: 0;
  list-style: none;
  counter-reset: counter;
}
ol.term li {
  /* 使用自訂標號 */
  counter-increment: counter;
  margin-left: 23px;
  text-indent: -23px;
}
ol.term li:before {
  content: "("counter(counter) ") ";
}
li {
  margin-top: 10px;
}