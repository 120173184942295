@import "src/css/variables.scss";

.frontFooter {
    background: linear-gradient(90deg, #ECECEC 0%, #F2F2F2 100%);;
}
.footerLeftSection {
    background: linear-gradient(90deg, var(--front-primary) 0%, #5B5A5A 51.56%, #4F4E4E 100%);
    margin-top: -16px;
    padding: 0;
}
.footerInfo {
    text-align: left;
    padding: 0 0 1em 2em;
}
.footerContentRow {
    margin-top: 16px;
}
.footerContentRow .title {
    color: var(--front-orange);
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 10px;
}
.footerContentRow .content {
    color: #fff;
    margin-bottom: 5px;
}
.footerContentRow .content:hover {
    color: #fff;
}
.footerContentRow ul.content {
    padding-left: 24px;
}
.footerContentRow ul.content li {
    margin-top: 5px;
}
.footerService {
    margin-bottom: 24px;
}
.footerService .ant-btn {
    padding: 0;
    margin-right: 15px;
}
.footerService img {
    width: 30px;
}
.frontFooterLogo {
    margin-top: 3em;
    width: 100px;
}
.frontFooterText {
    color: var(--front-primary);
    margin-top: 14px;
    font-size: 12px;
}

@media (max-width: 767px) {
    .frontFooterLogo {
        margin-top: 1em;
        width: 200px;
    }
}

@media (max-width: 600px) {
    .footerContentRow .link {
        display: flex;
        justify-content: flex-start;
        gap: 20px;
    }
    .footerService {
        display: flex;
        justify-content: flex-start;
    }
    .footerService .ant-btn {
        margin-right: 0;
        margin-left: 15px;
    }
}