@import "src/css/variables.scss";

.frontBreadcrumb {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: left;
  padding-left: 24px;
  position: fixed;
  background-color: #fff;
  z-index: 90;
}
.ant-breadcrumb {
  line-height: 40px;
}
.ant-breadcrumb a:hover {
  color: var(--front-secondary) !important;
}

@media (max-width: 600px) {
  .frontBreadcrumb {
    display: none;
  }
}