@import "src/css/variables.scss";

/* center */
.divider-center {
  display: flex;
  justify-content: center;     
}
.divider-center .divider {
  display: flex;
  justify-content: center;  
  align-items: center;
  border-bottom: 1px solid #fff;
  background-color: #D9D9D9;
  width: 30%;
  height: 2px;
  margin: 0.5em 0px 1.5em;
}
.divider-center .divider span {
  display: block;
  width: 30%;
  height: 4px;
  background-color: var(--front-secondary);
  border-radius: 20px;
}

/* left */
.divider-left {
  display: flex;
  justify-content: flex-start;     
}
.divider-left .divider {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #fff;
  background-color: #D9D9D9;
  width: 90%;
  height: 2px;
  margin: 0.5em 0px 1.5em;
}
.divider-left .divider span {
  display: block;
  width: 30%;
  height: 4px;
  background-color: var(--front-secondary);
  border-radius: 20px;
  margin-left: 2em;
}
.divider-left .reverse {
  justify-content: flex-end;
}
.divider-left .reverse span {
  margin-right: 2em;
}

/* for Jcoin */
.activity-divider {
  display: flex;
  align-items: center;
  margin: 1em -1em;
}
.activity-divider::before, .activity-divider::after {
  content: "";
  flex: 1;
  height: 40px;
}
.activity-divider::before {
  background: url("src/assets/activity/Jcoin_divider_left.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 1em 0 0;
}
.activity-divider::after {
  background: url("src/assets/activity/Jcoin_divider_right.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 0 0 1em;
}