.caseStageCard .submitBtn {
  margin-top: 20px;
}

.searchBox {
  margin: 10px 0 15px 0;
  padding: 15px;
  background-color: #eff2f7;
}

.caseStatusTable,
.casePercentTable {
  margin: 5px 0 10px 0;
}

.caseStatusTable .ant-table-tbody > tr:nth-child(5) > td {
  border-bottom: 1px solid #c5c5c5;
}

.casePercentTable .ant-table {
  margin-bottom: 10px;
}

.casePercentTable .ant-table-tbody > tr:nth-child(3) > td {
  border-bottom: 1px solid #c5c5c5;
}

.casePercentTable .ant-progress-show-info .ant-progress-outer {
  padding-right: calc(2em + 18px);
}

.casePercentTable .ant-progress-text {
  margin-left: 0;
}
