@import "src/css/variables.scss";

/* Header */
.jet-header {
  width: 100%;
  height: 65px;
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 99;
  padding: 0;
}
.jet-header-logo {
  float: left;
  margin: 0 24px;
}

/* Footer */
.jet-footer-content {
  background-color: var(--front-grey-01);
  padding: 30px 20px 25px;
  text-align: left;
}
.jet-footer-logo {
  width: 80%;
}
.jet-footer-content .title {
  color: "#707070";
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 10px;
}
.jet-footer-content .content {
  margin-bottom: 5px;
  margin-left: 10px;
  font-size: 18px;
}
.jet-footer-content img.content {
  margin-left: 5px;
}
.jet-footer-text {
  background-color: #fff;
  font-size: 12px;
  padding: 5px 0;
}

@media (max-width: 992px) {
  .jet-footer-logo {
    width: 95%;
  }
}

@media (max-width: 767px) {
  .jet-footer-logo {
    width: 180px;
    margin-bottom: 20px;
  }
}