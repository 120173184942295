:root {
  --front-primary: #333333;
  --front-secondary: #FA8C16;
  --front-required: #FF4D4F;
  --front-grey-01: #F0F0F0;
  --front-grey-02: #E5E5E5;
  --front-grey-03: #D5D5D5;
  --front-grey: #9E9E9E;
  --front-title: #4D4D4D;
  --front-red: #FF797B;
  --front-orange: #FEA57D;
  --front-blue: #0091CE;
  --front-green: #8CC63F;
  --front-line-green: #00B900;
  --front-light-yellow: #FFF5E7;
  --front-card-shadow: drop-shadow(-2px -2px 6px rgba(0, 0, 0, 0.05)) drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.05));
}