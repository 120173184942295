.storeCard .storeCardItem {
  padding: 3px 0;
  color: #324057;
  min-height: 38px;
}

.storeCard .storeCardItem .addrItem {
  margin-right: 10px;
}

.storeCard .submitBtn {
  margin-top: 40px;
}
