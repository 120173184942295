.loading-box {
  margin-top: 60px;
  margin-bottom: 50px;
}
.img-container {
  margin: auto;
  animation: floating 1.5s ease-in-out infinite;
  height: 150px;
  width: 150px;
  border-radius: 50%;

  background: url("src/assets/JetShop_logo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
}

@keyframes floating {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-50px);
  }

  100% {
    transform: translatey(0px);
  }
}

.img-shadow {
  margin-top: -20px !important;
  width: 200px;
  height: 30px;
  animation: expanding 1.5s infinite;
  border-radius: 50%;
  margin: auto;
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.15) 28.65%, rgba(0, 0, 0, 0) 100%);
}

@keyframes expanding {
  0%,
  100% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(0.75);
  }
}

.loading-text {
  margin-top: 20px;
  font-size: 20px;
  color: #4D4D4D;
  letter-spacing: 10px;
}