@import "src/css/variables.scss";

.contentLeft {
  text-align: left !important;
  margin: 0 3em;
}
.requiredText {
  color: var(--front-required);
  font-family: SimSun, sans-serif;
}
.contentLeft .ant-input,
.contentLeft .ant-select-selector {
  border-radius: 10px !important;
}
.contentLeft .ant-input:hover,
.contentLeft .ant-select-selector:hover {
  border-color: var(--front-primary) !important;
}
.contentLeft .ant-input:focus,
.contentLeft .ant-select-focused .ant-select-selector,
.contentLeft .ant-select-selector:focus,
.contentLeft .ant-select-selector:active {
  border-color: var(--front-primary) !important;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 2px rgba(33, 33, 33, 0.342) !important;
  box-shadow: 0 0 0 2px rgba(33, 33, 33, 0.342) !important;
}
.contentLeft label {
  font-size: 16px;
}
.contentLeft .ant-row.ant-form-item {
  margin-bottom: 20px;
}
.inputHint {
  font-size: xx-small;
  color: var(--front-grey);
  margin: 0;
}
.merAppVatNo, .merAddr {
  margin-bottom: 0px !important;
}
.success {
  display: flex;
  align-items: center;
  justify-content: center;
}
.submitSuccessText {
  color: var(--front-title);
  font-size: 24px;
  margin-left: 12px;
  margin-bottom: 0px;
}

@media (max-width: 600px) {
  .contentLeft {
    margin: 0 1em;
  }
  .contentLeft .ant-row.ant-form-item {
    margin-bottom: 10px;
  }
}