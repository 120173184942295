@import "src/css/variables.scss";

.persona {
  display: flex;
  justify-content: center;
  align-items: center;
}
.persona img {
  width: 25%;
}
.dialog {
  width: 300px;
  margin: 0 2em;
  padding: 24px;
  border-radius: 20px;
  text-align: left;
  color: #000 !important;
}
.dialog.red {
  background-color: #FFE3EA;
}
.dialog.green {
  background-color: #E2F6F4;
}
.dialog.blue {
  background-color: #DDF4FF;
}
.dialog.yellow {
  background-color: var(--front-light-yellow);
}
.dialog .dialogTitle {
  font-size: 18px;
}
.dialog p {
  margin: 0;
}

/* persona swiper(for pad and phone) */
.personaSwiper {
  margin-top: 20px;
}
.personaImg {
  display: flex;
  justify-content: center;
}
.personaImg img {
  width: 120px !important;
}
.personaSwiper .swiper-slide .dialog {
  margin: 10px auto 0;
  width: 250px;
}
.personaSwiper .swiper-button-prev, .personaSwiper .swiper-button-next {
  top: 60% !important;
}