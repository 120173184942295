.userEditCard .userEditCardContent {
    padding: 3px 0;
    color: #324057;
    min-height: 38px;
}

.userEditCardContent .userEditCardItem {
    margin-top: 10px;
    margin-left: 15px;
    padding: 10px 0px 0px 10px;
}

.userEditCard .submitBtn {
    margin-top: 20px;
}
