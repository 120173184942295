.jet-sample {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.jet-sample-img {
  width: 90%;
}

/* Button */
.jet-btn.ant-btn,
.jet-btn.ant-btn:focus {
  border-color: var(--front-primary);
  color: var(--front-primary);
}
.jet-btn.ant-btn:hover {
  background-color: var(--front-primary);
  color: #fff;
}
.jet-btn-filled.ant-btn,
.jet-btn-filled.ant-btn:focus {
  border-color: var(--front-primary);
  background-color: var(--front-primary);
  color: #fff;
}

/* 預覽-Input */
.front .ant-modal-body .ant-input,
.front .ant-modal-body .ant-btn {
  border-radius: 50px !important;
  color: var(--front-primary);
}
.front .ant-modal-body .ant-input:hover,
.front .ant-modal-body .ant-btn:hover {
  border-color: var(--front-primary);
  background-color: #fff;
  color: var(--front-primary);
}
.front .ant-modal-body .ant-input:focus,
.front .ant-modal-body .ant-btn:focus,
.front .ant-modal-body .ant-btn:active {
  border-color: var(--front-primary) !important;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 2px rgba(33, 33, 33, 0.342) !important;
  box-shadow: 0 0 0 2px rgba(33, 33, 33, 0.342) !important;
}
.front .ant-modal-body .ant-btn {
  margin-left: 10px;
}

@media (max-width: 576px) {
  .jet-form .ant-form-item {
    margin-bottom: 5px;
  }
}