.header {
  height: 60px;
  min-height: 60px;
  background-color: #324057;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 99;
}

.menuList {
  float: right !important;
  width: 100%;
  min-width: auto;
  padding: 0px;
  top: -4px; /* for default before */
}

.headerBtn,
.headerBtn:hover,
.headerBtn:focus {
  height: 100%;
  min-width: 85px;
  background: #324057;
  color: #fff;
  padding: 0px 5px;
  border: none;
}

.menuList .ant-dropdown-menu-item {
  padding: 10px 12px;
}

.menuList .ant-dropdown-menu-item:hover {
  background-color: #324057 !important;
  color: #fff;
}

.header a {
  text-decoration: none;
}
