/* 維護後台css component */
.row-dragging {
  background: #f0f0f0;
  border: 1px solid #f0f0f0;
}
.row-dragging td {
  padding: 10px 16px;
}
.row-dragging .drag-visible {
  visibility: visible;
}
.rootList {
  height: 100%;
  text-align: left;
  overflow: scroll;
}
.rootListCard {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0;
  margin-bottom: 20px;
}
.rootListSearch {
  padding: 15px;
  background-color: #EFF2F7;
}
.rootFormItem {
  padding: 24px 24px 0 24px;
}
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
.rootSwitch.ant-switch {
  background-color: #1890ff;
}
.rootSwitch.ant-switch.ant-switch-checked {
  background-color: rgba(0, 0, 0, 0.3);
}
.ant-notification-top {
  inset: 24px auto auto 50%;
  transform: translateX(-50%);
  top: 24px !important;
  right: auto !important;
  margin-left: 0;
  margin-right: 0;
}

/* text editor */
.rdw-editor-main {
  background: white;
}
.faq-list-content p {
  margin-bottom: 0;
}