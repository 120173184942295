@import "src/css/variables.scss";

/* Section1 */
.frontEcommerceSection1 {
  padding-left: 5em;
}
.frontEcommerceIntro {
  text-align: left;
  margin-top: 50px;
}
.frontEcommerceIntro img {
  width: 200px;
}
.frontEcommerceIntro h2 {
  margin-top: 0;
  font-size: 24px !important;
}
.frontEcommerceFeature {
  background-image: url("src/assets/ecommerce/background.png");
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  text-align: left;
  min-height: 520px;
}
.frontEcommerceFeature li {
  margin-top: 0;
  font-size: 18px;
  line-height: 35px;
  margin-left: -20px;
}

/* Section3-1 */
.frontEcommerceSection3 {
  background-image: url("src/assets/ecommerce/background_linear.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.frontEcommerceStep img {
  width: 130px;
}
.frontEcommerceStep .step {
  height: 170px;
  border: 3px #fff solid;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
}
.frontEcommerceStep .number {
  margin: 10px 15px;
  background-color: var(--front-grey);
  width: 40px;
  height: 40px;
  border: 3px #fff solid;
  border-radius: 100%;
  line-height: 32px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

/* Section3-2 */
.featureBox {
  width: 100%;
  min-height: 300px;
  padding-top: 2em;
}
.featureBox img {
  width: 90px;
}
.featureBox.red {
  background-color: var(--front-red);
}
.featureBox.orange {
  background-color: var(--front-orange);
}
.featureBox.blue {
  background-color: var(--front-blue);
}
.featureBox.green {
  background-color: var(--front-green);
}

/* Section4 */
.frontEcommerceProfit {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--front-light-yellow);
  width: 80%;
}
.shopIcon {
  width: 35%;
  position: absolute;
  left: -10px;
}
.frontEcommerceProfit div {
  margin: 3em 0 3em 5em;
}
.frontEcommerceProfit p {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 12px;
}
.textHighlight {
  font-size: 30px;
  color: var(--front-secondary);
  font-weight: bold;
}

/* Section5 */
.additional-service-btn {
  margin-top: 30px;
  border: none;
  background-color: transparent;
}
.additional-service-btn .unhover-img,
.additional-service-btn .hover-img {
  width: 60%;
}
.additional-service-btn .hover-img {
  display: none;
}
.additional-service-btn:hover .unhover-img {
  display: none;
}
.additional-service-btn:hover .hover-img {
  display: inline;
}

.service-info {
  background: linear-gradient(to top, #f8f8f8 0%, #f8f8f8 72%, #fff 72%, #fff 100%);
  margin-top: 70px;
  padding: 0 0 4em;
}

.service-info .swiper-button-prev {
  left: -10px;
  width: 40px;
  height: 40px;
  background-image: url("src/assets/ecommerce/arrow_left.png");
  background-size: cover;
  text-align: center;
}
.service-info .swiper-button-next {
  right: -10px;
  width: 40px;
  height: 40px;
  background-image: url("src/assets/ecommerce/arrow_right.png");
  background-size: cover;
  text-align: center;
}
.service-info .swiper-button-next::after, .service-info .swiper-button-prev::after{
  content: none;
}

.service-box .service-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-box .service-img img {
  width: 150px;
}
.service-box p {
  margin-bottom: 0;
}
.service-box .title {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;
}

@media (max-width: 1150px) {
  /* Section1 */
  .frontEcommerceSection1 {
    padding-left: 3em;
  }
}

@media (max-width: 850px) {
  /* Section1 */
  .frontEcommerceFeature {
    min-height: 350px;
  }
  .additional-service-btn .unhover-img,
  .additional-service-btn .hover-img {
    width: 70%;
  }
}

@media (max-width: 800px) {
  /* Section1 */
  .frontEcommerceFeature {
    min-height: 10px;
  }

  /* Section3-1 */
  .frontEcommerceStep h4 {
    font-size: 14px !important;
  }

  /* Section4 */
  .frontEcommerceProfit p,
  .frontEcommerceProfit .front {
    font-size: 18px;
    margin-bottom: 9px;
  }
  .textHighlight {
    font-size: 20px !important;
  }
}

@media (max-width: 600px) {
  /* Section1 */
  .frontEcommerceSection1 {
    padding-left: 1em;
  }
  .frontEcommerceIntro {
    margin-top: 20px;
  }
  .frontEcommerceIntro img {
    width: 150px;
  }
  .frontEcommerceIntro h2 {
    font-size: 18px !important;
  }
  .frontEcommerceFeature li {
    font-size: 14px;
    line-height: 25px;
  }

  /* Section3-2 */
  .featureBox img {
    width: 80px;
  }

  /* Section4 */
  .frontEcommerceProfit {
    width: 100%;
  }
  .shopIcon {
    position: relative;
    width: 55%;
    margin-bottom: -2em;
    left: 0;
  }
  .frontEcommerceProfit div {
    margin: 3em 1em;
  }
  .frontEcommerceProfit p,
  .frontEcommerceProfit .front {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .textHighlight {
    font-size: 18px !important;
  }

  /* Section5 */
  .additional-service-btn {
    margin-top: 10px;
  }
  .additional-service-btn .unhover-img {
    content: url("src/assets/ecommerce/additional_service_btn_mobile.png");
  }
  .additional-service-btn .hover-img {
    content: url("src/assets/ecommerce/additional_service_btn_hover_mobile.png");
  }
  .additional-service-btn .unhover-img,
  .additional-service-btn .hover-img {
    width: 80%;
  }
  .service-info {
    margin-top: 30px;
  }
}