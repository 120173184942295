.formItem {
    padding: 0 10px !important;
    height: 6px !important;
}

.submitBtn {
    margin-top: 20px;
}

.orderSearch {
    margin-top: 10px;
    padding: 15px;
    background-color: #EFF2F7;
}

@media screen and (max-width: 575px) {
    .submitBtn {
        margin-top: 6em;
    }
}

/* Document */
.doc-wrapper * {
    font-size: 16px;
    font-weight: 600;
}
.doc-wrapper {
    width: 1003px;
    margin: 20px auto;
}
.doc-wrapper p {
    position: relative;
}
.doc-wrapper .doc-data {
    display: inline-block;
    text-align: center;
    padding: 10px;
    font-weight: normal;
}
.title-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title-top p {
    margin: 0;
}
.form-content {
    border: 1px solid #1F497D;
    padding: 5px;
}
.form-content h3 {
    text-align: center;
    font-size: 1.6rem;
    margin: 10px auto;
    letter-spacing: 16px;
}
.form-content ul {
    padding: 0 10px 15px 50px;
    position: relative;
}
.form-content li {
    list-style: trad-chinese-informal;
    margin: 0 0 20px 0;
    line-height: 27px;
}
.line {
    border-bottom: 1px solid #000 !important;
}
.stamp {
    border: 1px solid #D6D6D6;
    position: absolute;
    width: 260px;
    height: 150px;
    top: 400px;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5%;
}
.stamp img {
    max-width: 180px;
    max-height: 130px;
}
.sign {
    position: absolute;
    width: 280px;
    top: 0;
    left: 260px;
}
.sign img {
    max-width: 260px;
    max-height: 35px;
}
.inline-block {
    width: 310px;
    display: inline-block;
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left !important;
}

/* table */
.table-s1 {
    width: 102%;
    margin: 0 0 15px -30px;
}
.table-s1 td {
    padding: 0px 10px;
    position: relative;
    border: 1px solid #000;
}
.table-s1 em {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 11px;
    color: #1F497D;
    font-style: normal;
}

/* color */
.red {
    color: #FF0000;
}

/* width */
.w-70 {
    width: 70px;
}
.w-90 {
    width: 90px;
}
.w-110 {
    width: 110px;
}
.w-150 {
    width: 150px;
}
.w-240 {
    width: 240px;
}
.w-290 {
    width: 290px;
}
.w-310 {
    width: 310px;
}
.w-365 {
    width: 365px;
}

/* margin */
.mb-16 {
    margin-bottom: 16px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-15 {
    margin-bottom: 15px;
}
.mb-20 {
    margin-bottom: 20px;
}

/* document-image */
.doc-img {
    margin: 30px auto 5px;
}
.doc-img h3 {
    font-size: 1.3rem;
    color: #4E4E4E;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col-6 {
    -webkit-box-flex: 0;
    width: 50%;
}
.row h6 {
    text-align: center;
    font-size: 1.3rem;
    color: #4e4e4e;
    margin-bottom: 10px;
}
.img-id {
    display: flex;
    justify-content: center;
    align-items: center;
}
.img-fluid {
    max-width: 80%;
}

@media print {
    .doc-wrapper * {
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact;
        font-size: 9px;
        font-weight: bold;
    }
    .doc-wrapper {
        width: 720px;
        margin: 10px auto;
    }
    .doc-wrapper div {
        page-break-inside: avoid;
    }
    .form-content h3 {
        font-size: 1.1rem;
        letter-spacing: 13px;
    }
    .table-s1 {
        page-break-inside: avoid;
        width: 670px;
        margin: 0 0 15px -20px;
    }
    .table-s1 td {
        padding: 0px 2px;
    }
    .table-s1 em {
        right: 5px;
        font-size: 8px;
    }

    /* width */
    .w-70 {
        width: 30px;
    }
    .w-110 {
        width: 70px;
    }
    .w-240 {
        width: 180px;
    }
    .w-290 {
        width: 200px;
    }
    .w-310 {
        width: 230px;
    }
    .w-365 {
        width: 345px;
    }

    .line {
        padding: 2px 10px;
    }
    .title-top p {
        font-weight: bold;
    }
    .form-content {
        padding: 0px;
    }
    .form-content ul {
        padding: 0 5px 15px 40px;
    }
    .form-content li {
        margin: 0 0 15px 0;
        line-height: 20px;
        letter-spacing: 0px;
    }
    .stamp {
        top: 340px;
        right: 30px;
        width: 180px;
        height: 100px;
    }
    .stamp img {
        max-width: 160px;
        max-height: 80px;
    }
    .inline-block{
        width: 220px;
    }

    /* margin */
    .mb-10 {
        margin-bottom: 5px !important;
    }
    .mb-15 {
        margin-bottom: 10px !important;
    }
    .mb-20 {
        margin-bottom: 15px !important;
    }
    .mb-20 .w-365 {
        width: 260px;
    }
    .doc-img {
        page-break-before: always;
    }
    .doc-img h3 {
        font-size: 1.1rem;
    }
    .row h6 {
        font-size: 1.1rem;
    }
}