@import "src/css/variables.scss";

/* Section1 */
.frontHomeSection1 {
  background-image: url("src/assets/home/background.png");
  background-repeat: no-repeat;
  background-position: top left center;
  background-size: cover;
}
.frontHomeLogo {
  width: 170px;
}
.redText {
  font-size: 60px;
  font-style: italic;
  color: var(--front-red);
}
.frontSwiper.swiper {
  margin-top: 20px;
  width: 80%;
}
.frontLandingBtn-light,
.frontLandingBtn-dark {
  padding: 8px 16px;
  cursor: pointer;
  border: 3px var(--front-title) solid;
  transition: all 0.5s;
  border-radius: 10px;
  position: relative;
}
.frontLandingBtn-light:hover,
.frontLandingBtn-dark:hover {
  transition: all 0.5s;
  box-shadow: 0px 6px 15px #00000050;
  padding: 8px 22px;
}
.frontLandingBtn-light {
  background: #ffffff;
  color: var(--front-title);
}
.frontLandingBtn-dark {
  background: var(--front-title);
  color: #ffffff;
}
.new-icon {
  position: absolute;
  width: 70px;
  left: -35px;
  top: -35px;
}

/* Section2, 3 */
.frontHomeIntro {
  background: linear-gradient(103.93deg, #fff 0%, #F4F4F4 46.35%, #F6F6F6 100%);
  width: 85%;
  position: relative;
  padding: 24px 8px;
}
.frontHomeTitle {
  width: fit-content;
  padding: 4px 16px;
  color: #ffffff;
  border-radius: 8px;
  /* position: relative; */
}
.frontHomeTitle.jetdai {
  background: var(--front-orange);
}
.frontHomeTitle.installment {
  background: var(--front-red);
}
.frontHomeTitle.ecommerce {
  background: var(--front-blue);
}
.frontHomeTitle.merchant {
  background: var(--front-green);
}
.frontHomeSubTitle .front {
  font-size: 18px;
}
.frontHomeIntro .content {
  color: #5c5c5c;
  font-size: 16px;
}

@media (max-width: 765px) {
  /* Section1 */
  .frontSwiper.swiper {
    width: 90%;
  }
}

@media (max-width: 600px) {
  /* Section1 */
  .frontHomeLogo {
    width: 110px;
  }
  .redText {
    font-size: 35px;
  }
}