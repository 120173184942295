@import "src/css/variables.scss";

/* animated arrow */
.animated-arrow {
  position: relative;
  width: 100px;
	height: 200px;
	margin: 0 20px 20px 0;
}

.animated-arrow div {
  position: absolute;
	top: 0;
  bottom: 0;
  left: 0;
  right: 0;
	margin: auto;
}

.animated-arrow .arrow-main {
  height: 10px;
  width: 10px;
	border: 1px solid rgba(0, 0, 0, 0.6);
	border-width: 3px 3px 0 0;
	transform: rotate(45deg);
}

.arrow-main::before,
.arrow-main::after {
  content: '';
	position: absolute;
	display: block;
	height: 10px;
  width: 10px;
	border-width: 3px 3px 0 0;
}

.arrow-main::before {
  border: 1px solid rgba(0, 0, 0, 0.3);
	border-width: 3px 3px 0 0;
	top: 7px;
  left: -10px;
}

.arrow-main::after {
  border: 1px solid rgba(0, 0, 0, 1);
	border-width: 3px 3px 0 0;
	top: -13px;
  left: 10px;
}