.product .btnText {
  padding-left: 3px;
}

.product .anticon-arrow-up,
.product .anticon-arrow-down {
  border: 1px solid;
  border-radius: 10px;
}

.product .ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.85);
}