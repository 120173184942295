.menuHide {
  height: 60px;
  min-width: 50px; 
  background-color: #324057;
  padding-left: 0px !important;
  padding-right: 0px !important;
  position: fixed;
  top: 0px;
  z-index: 99;
  text-align: left;
}

.menuHide .menuIcon {
  padding-right: 10px;
  margin-bottom: 4px;
}

.menuHide .logoImg {
  width: 65px;
}

.menuHide a {
  text-decoration: none;
}

.menuHide button {
  background-color: #324057;
  color: #fff;
  width: 50px;
  height: auto;
  border: none;
  cursor: pointer;
  font-size: 20px;
}


/* cover Responsive Ant Menu default style */
.ant-popover-inner-content {
  padding: 0px 0px !important;
}

.ant-popover-placement-bottom {
  padding-top: 0px;
}

.ant-popover-arrow {
  display: none;
}

/* .responsive-menu {
  display: none;
} */

.responsive-menu .ant-menu {
  box-shadow: none;
}

.responsive-menu .ant-menu-submenu-title {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.responsive-menu .ant-menu-submenu-title:hover,
.responsive-menu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow,
.responsive-menu .ant-menu-submenu-active,
.responsive-menu .ant-menu-item-active,
.responsive-subMenu .ant-menu-item-active {
  background-color: #324057;
  color: #fff !important;
}

.responsive-subMenu .ant-menu-item {
  margin: 0px !important;
}