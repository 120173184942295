@import "src/css/variables.scss";

.frontHeader {
    width: 100%;
    height: 65px;
    background: linear-gradient(270deg, #fff 0%, #E0E0E0 50.52%, #fff 100%);
    position: fixed;
    z-index: 99;
    padding: 0;
}
.frontHeaderLogo {
    float: left;
    margin: 0 24px;
}
.frontMenu {
    float: right;
    margin-top: 16px;
    padding: 0 2em;
    width: fit-content;
    height: 65px;
    border-radius: 50px 0 0 50px;
    background: linear-gradient(90deg, var(--front-primary) 0%, #6A6A6A 100%);;
    text-align: center;
}
.frontMenu a {
    color: #fff;
    font-size: 16px;
    padding: 0;
}
.frontMenu a:hover {
    color: var(--front-orange);
    text-decoration: underline;
}
.frontSubMenu li,
.frontSubMenuChild .ant-dropdown-menu-submenu-title {
    height: 50px !important;
    font-size: 16px !important;
}
.frontSubMenu li:hover,
.frontSubMenuChild .ant-dropdown-menu-submenu-title:hover {
    background-color: var(--front-primary);
    color: #fff;
}
.frontSubMenuChild {
    margin-top: 0;
}
.frontSubMenuChild .ant-dropdown-menu-submenu-title,
.frontSubMenuChild .ant-dropdown-menu-submenu-expand-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.drawerBtn,
.drawerBtn:hover,
.drawerBtn:focus {
    float: right;
    height: 100%;
}
.frontDrawer .ant-drawer-body {
    padding: 0;
    background-color: var(--front-primary);
}
.frontDrawer .ant-drawer-body {
    padding: 0;
}
.frontDrawerMenu {
    background-color: var(--front-primary) !important;
    color: #fff;
}
.frontDrawerMenu .ant-menu-item-selected {
    background-color: #fff !important;
}
.frontDrawerMenu .ant-menu-item-selected a {
    color: var(--front-primary) !important;
}
.frontDrawerMenu .ant-menu-item-selected:hover {
    color: var(--front-primary);
}

@media (max-width: 880px) {
    .frontHeader {
        background: rgba(255, 255, 255, 0.95);
    }
}