.home {
  color: #324057;
  height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.homeContent {
  /* height: 100%; */
  padding-top: 20px;
  padding-left: 0px !important;
  padding-right: 0px !important; 
}

.homeNotify {
  background: #73879C;
  color: #fff;
  margin-top: 10px;
  /* width: 1000px; */
  /* margin-left: auto;
  margin-right: auto; */
  /* padding: 2px 0; */
}

.home .menuIcon {
  font-size: 14px;
  padding: 5px 5px;
}

.carouselItems {
  /* height: 540px; */
  width: 100%;
  background-color: #C4C4C4;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0px;
  padding-right: 0px;
}

.carouselItems img {
  width: 100%;
  max-height: 540px;
}

.home h3 {
  margin-bottom: 0px !important;
  color: #324057;
}

.home .leftArrow {
  z-index: 99;
}

.home .rightArrow {
  z-index: 99;
}