/*--------------------------
Product detail
---------------------------*/
/* Card */
.frontProductCard {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    margin-bottom: 2em;
}
.prodIntro {
    text-align: left;
    margin-left: 1em;
}
/* Text */
.prod-name, .text {
    margin-bottom: 10px;
}
.prod-name {
    font-size: 24px;
    font-weight: bold;
}
.text {
    font-size: 16px;
}
/* Tag */
.front.ant-tag {
    border-radius: 100px;
    padding: 5px 10px;
}
/* Select */
.select-spec {
    width: 50%;
}
.select-spec .ant-select-selector:hover {
  border-color: #333333 !important;
}
.select-spec.ant-select-focused .ant-select-selector,
.select-spec.ant-select-selector:focus,
.select-spec.ant-select-selector:active {
  border-color: #333333 !important;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 2px rgba(33, 33, 33, 0.342) !important;
  box-shadow: 0 0 0 2px rgba(33, 33, 33, 0.342) !important;
}
/* 說明 */
.description {
    color: #8C8C8C;
    margin-bottom: 10px;
}
/* 分期期數按鈕 */
.period-btn {
    margin-right: 16px;
    margin-bottom: 10px;
}
.period-btn:hover,
.period-btn.ant-radio-button-wrapper-checked
{
    background-color: #333333 !important;
    color: #fff !important;
    border-color: #333333 !important;
}
.period-btn::before {
    background: #D9D9D9 !important;
}
.period-btn:active, .period-btn:focus {
    background-color: #333333 !important;
    color: #fff !important;
    -webkit-box-shadow: 0 0 0 2px rgba(33, 33, 33, 0.342);
    box-shadow: 0 0 0 2px rgba(33, 33, 33, 0.342);
}
/* 分期金額 */
.amount {
    font-size: 24px;
    color: red;
}
.period {
    font-size: 14px;
}
/* 立即分期按鈕、回上頁按鈕 */
.back:hover, .back:focus {
    border-color: #333333;
    color: #333333;
}
.back:active {
    border-color: #333333;
    color: #333333;
    -webkit-box-shadow: 0 0 0 2px rgba(33, 33, 33, 0.342);
    box-shadow: 0 0 0 2px rgba(33, 33, 33, 0.342);
}
.install {
    margin-left: 20px;
}
.install, .install:hover, .install:focus {
    background-color: #333333;
    color: #fff;
    border-color: #333333;
}
.install:active {
    background-color: #333333;
    color: #fff;
    -webkit-box-shadow: 0 0 0 2px rgba(33, 33, 33, 0.342);
    box-shadow: 0 0 0 2px rgba(33, 33, 33, 0.342);
}
/* 商品贈品說明、商品說明標題 */
.prod-title {
    background-color: #F5F5F5;
    height: 45px;
    line-height: 45px;
    border-radius: 100px;
    text-align: start;
    padding-left: 20px;
}
/* 商品說明圖片 */
.product-img {
    margin-top: 20px;
    width: 60%;
}

@media (max-width: 768px) {
    .select-spec {
        width: 100%;
    }
}
@media (max-width: 600px) {
    .product-img {
        width: 100%;
    }
}

/*--------------------------
Jet捷店官網
---------------------------*/
.jet-bg {
    background-image: url("src/assets/jet/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-bottom: 20px;
}
.jet-bg h1 {
    margin-top: 0px;
    font-size: 32px;
    font-weight: bold;
}
.jet-bg h2 {
    margin-top: 0;
    font-size: 22px;
}
.jet-title {
    background-color: var(--front-light-yellow);
    width: 200px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 100px;
    padding: 5px 0;
    margin: 20px 0;
}
.jet-swiper .ant-card {
    display: inline-block;
    cursor: pointer;
}
.jet-swiper .card-img {
    display: flex;
    justify-content: center;
    align-items: center;
}
.jet-swiper .ant-card img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    object-fit: cover;
}
.jet-swiper .ant-card:hover img {
    animation: shadowEffect 0.25s;
    animation-fill-mode: forwards;
}
.jet-swiper .swiper-pagination-bullet-active {
    background-color: var(--front-primary);
}
.jet-h-scroll {
    overflow: auto;
    white-space: nowrap;
}
.jet-h-scroll .ant-tag {
    margin: 25px 6px 15px 0;
    padding: 1px 5px;
}
.act-prod-name {
    font-size: 20px;
    margin-bottom: 15px;
}
.act-prod-amount {
    font-size: 16px;
}
.act-prod-amount span {
    font-size: 18px;
    font-weight: bold;
    color: red;
}
.jet-prod-list-col .ant-card {
    border-radius: 20px;
}
.jet-category-menu,
.jet-prod-list-col {
    margin: 20px 0 10px;
    display: flex;
    justify-content: center;
}
.jet-prod-search {
    margin: 20px 0 10px;
    display: flex;
    justify-content: space-around;
}
.jet-prod-list-col .ant-card-body img {
    width: 150px;
    height: 150px;
    object-fit: contain;
}
.jet-prod-tag {
    text-align: left;
    height: 54px;
}
.jet-prod-tag .ant-tag {
    margin-top: 15px;
}
.jet-prod-info,
.jet-card-footer {
    text-align: left;
}
.jet-prod-info {
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.jet-prod-info .title {
    margin-top: 0px;
    font-size: 16px;
    font-weight: bold;
    
}
.jet-card-footer {
    margin-top: 10px;
}
.jet-card-footer p {
    margin-bottom: 0;
}
.jet-card-footer span {
    font-size: 16px;
    color: red;
    font-weight: bold;
}
.jet-note-box {
    width: 90%;
    padding: 30px;
    border-radius: 20px;
    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.05);
}
.jet-step .ant-col {
    margin-top: 30px;
    display: flex;
    align-items: center;
}
.jet-step .ant-col#step1,
.jet-step .ant-col#step2 {
    justify-content: start;
}
.jet-step .ant-col#step3,
.jet-step .ant-col#step4 {
    justify-content: end;
}
.jet-step .title {
    font-size: 18px;
    font-weight: bold;
}
.jet-step .detail {
    color: #808080;
    margin-bottom: 10px;
    font-size: 16px;
}
.jet-step div {
    text-align: left;
    margin-left: 20px;
}
.jet-step img {
    width: 80px;
}

/* Input, Select */
.jet-prod-search .ant-input-affix-wrapper,
.jet-prod-search .ant-select-selector {
    border-radius: 100px !important;
}
.jet-prod-search .ant-input-affix-wrapper:hover,
.jet-prod-search .ant-select-selector:hover {
    border-color: var(--front-primary) !important;
}
.jet-prod-search .ant-input-affix-wrapper-focused,
.jet-prod-search .ant-select-focused .ant-select-selector,
.jet-prod-search .ant-select-selector:focus,
.jet-prod-search .ant-select-selector:active {
    border-color: var(--front-primary) !important;
    outline: 0 !important;
    --webkit-box-shadow: 0 0 0 2px rgba(33, 33, 33, 0.342) !important;
    box-shadow: 0 0 0 2px rgba(33, 33, 33, 0.342) !important;
}
.jet-prod-search .ant-select {
    float: right;
    width: calc(100% - 95px);
}
.jet-prod-search .ant-select-selector span {
    text-align: left;
}

/* Card */
.jet-prod-list-col .ant-card {
    filter: var(--front-card-shadow);
    width: 85%;
}
.jet-prod-list-col .ant-card-body {
    padding: 12px;
}

/* Menu */
.jet-category-menu .ant-menu-root {
    width: 90%;
    height: fit-content;
    filter: var(--front-card-shadow);
}
.jet-category-menu .ant-menu-root,
.jet-category-menu .ant-menu-root ul,
.jet-category-menu .ant-menu-root .ant-menu-inline li {
    border-radius: 20px;
}
.jet-category-menu .ant-menu-root .ant-menu-inline.ant-menu-sub,
.jet-category-menu .ant-menu {
    background-color: #fff;
}
.jet-category-menu .ant-menu-item,
.jet-category-menu .ant-menu-submenu-title {
    color: var(--front-title);
}
.jet-category-menu .ant-menu-item-selected,
.jet-category-menu .ant-menu-root .ant-menu-item-selected .anticon,
.jet-category-menu .ant-menu-root .ant-menu-item-selected .anticon + span,
.jet-category-menu .ant-menu-root .ant-menu-item:hover {
    background-color: #fff !important;
    color: var(--front-secondary) !important;
}
.jet-category-menu .ant-menu-submenu-arrow::before, 
.jet-category-menu .ant-menu-submenu-arrow::after {
    background: var(--front-title) !important;
}
.jet-category-menu .ant-menu-submenu-title:hover {
    color: var(--front-title);
}
.jet-category-menu .ant-menu-inline::after {
    display: none;
}
.jet-category-menu .ant-menu-item-disabled {
    color: var(--front-title) !important;
    pointer-events: none;
}

/* Dropdown */
.jet-dropdown {
    margin-top: 5px;
    border-radius: 10px;
    width: 180px;
    max-height: 300px;
    overflow: auto;
}
.jet-dropdown li {
    line-height: 30px;
}
.jet-dropdown .ant-dropdown-menu-item-group-title {
    padding-top: 0;
    padding-bottom: 0;
}
.jet-dropdown .ant-dropdown-menu-item-group-list {
    margin: 0;
}
.jet-dropdown .ant-dropdown-menu-item-selected {
    background-color: var(--front-primary);
    color: #fff;
}

/* Pagination */
.jet-pagination li,
.jet-pagination .ant-pagination-prev .ant-pagination-item-link, 
.jet-pagination .ant-pagination-next .ant-pagination-item-link {
    border-radius: 100px !important;
}
.jet-pagination .ant-pagination-item-active,
.jet-pagination .ant-pagination-item:hover,
.jet-pagination li:not(.ant-pagination-disabled) .ant-pagination-item-link:hover {
    border-color: var(--front-secondary);
}
.jet-pagination .ant-pagination-item-active a,
.jet-pagination .ant-pagination-item:hover a,
.jet-pagination li:not(.ant-pagination-disabled) .ant-pagination-item-link:hover span {
    color: var(--front-secondary);
}
.jet-pagination .ant-pagination-options {
    display: none;
}

@media (max-width: 767px) {
    .jet-bg h2 {
        font-size: 18px;
    }
}
@media (max-width: 575px) {
    .jet-prod-search .ant-select {
        margin-top: 10px;
    }
    .jet-prod-list-col .ant-card {
        width: 70%;
    }
    .jet-step .ant-col {
        justify-content: start !important;
        margin-top: 10px;
    }
    .jet-step img {
        width: 60px;
    }
}

/* animation */
@keyframes shadowEffect {
    0% { box-shadow: 0px 0px 0px 5px var(--front-light-yellow); }
    25% { box-shadow: 0px 0px 0px 10px var(--front-light-yellow); }
    50% { box-shadow: 0px 0px 0px 15px var(--front-light-yellow); }
    100% { box-shadow: 0px 0px 0px 20px var(--front-light-yellow); }
}