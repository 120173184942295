@import "src/css/variables.scss";

/* Section1 */
.frontMerchantSection1 {
  padding-left: 5em;
  background-image: url("src/assets/merchant-guide/background.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  min-height: 500px;
}
.frontMerchantTitle {
  text-align: left;
  margin-top: 100px;
  margin-left: 50px;
}
.frontMerchantTitle h2 {
  margin-top: 0;
  font-size: 24px !important;
}

/* Section2 */
.linearBox {
  background: linear-gradient(103.93deg, #fff 0%, #F4F4F4 46.35%, #F6F6F6 100%);
  width: 85%;
  position: relative;
}
.linearBox img {
  margin-top: 24px;
  width: 130px;
}
.textBox {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 5px;
}
.textBox div {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.textBox p {
  font-size: 24px;
  color: #fff;
  font-weight: bold;
}
.textBox.red div,
.colorBox.red {
  background-color: var(--front-red);
}
.textBox.orange div,
.colorBox.orange {
  background-color: var(--front-orange);
}
.textBox.blue div,
.colorBox.blue {
  background-color: var(--front-blue);
}
.linearBox h4 {
  color: var(--front-grey);
  margin: 0 24px 24px;
}
.frontLink{
  width: 100%;
  height: 30px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: end;
}
.frontLink a, .frontLink button {
  padding: 0;
  background-color: transparent;
  color: var(--front-primary);
  border: none;
}
.frontLink a:hover, .frontLink button:hover {
  border-bottom: 1px dashed;
}

/* Section3 */
.colorBox {
  width: 100%;
  min-height: 150px;
  display: flex;
  justify-content: center;  
  align-items: center;
}
.colorBox h4 {
  color: #fff;
  margin: 10px 24px;
}

/* Section4 */
.linearBackground {
  padding: 2em 10em;
  background: linear-gradient(90deg, #F0F0F0 0%, #fff 50.52%, #F0F0F0 100%);
}
.industrySection {
  margin: 20px 10px;
  display: flex;
  justify-content: center;
}
.industrySection div {
  width: 140px;
  height: 140px;
  background: linear-gradient(103.93deg, #fff 0%, #FAFAFA 46.35%, #FAFAFA 100%);
  box-shadow: -2px -2px 6px rgba(0, 0, 0, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.1);
  border: 10px solid #fff;
  border-radius: 100%;
}
.industrySection img {
  width: 80px;
}

/* Section5 */
.comingSoon {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
.comingSoon div {
  width: 70%;
  height: 280px;
  background-color: var(--front-light-yellow);
  padding-top: 50px;
  box-shadow: 15px 15px 0px var(--front-grey-02);
}
.coming {
  position: absolute;
  top: -60px;
  left: 60px;
  width: 300px;
}
.soon {
  position: absolute;
  bottom: -65px;
  right: 60px;
  width: 300px;
}
.comingSoonContent {
  margin: 10px 10em 0;
}

@media (max-width: 1150px) {
  /* Section1 */
  .frontMerchantSection1 {
    padding-left: 3em;
    background-position: bottom right -200px;
  }

  /* Section4 */
  .linearBackground {
    padding: 2em 0;
  }
}

@media (max-width: 850px) {
  /* Section1 */
  .frontMerchantSection1 {
    padding-left: 3em;
    background-position: bottom right -200px;
  }
  .frontMerchantTitle {
    margin-left: 0;
  }
}

@media (max-width: 765px) {
  /* Section2 */
  .linearBox {
    width: 90%;
  }

  /* Section4 */
  .industrySection img {
    width: 60px;
  }
  .industrySection div {
    width: 100px;
    height: 100px;
  }

  /* Section5 */
  .comingSoonContent {
    margin: 10px 5em 0;
  }
}

@media (max-width: 600px) {
  /* Section1 */
  .frontMerchantSection1 {
    padding-left: 1em;
    background-position: bottom right 0px;
    min-height: 350px;
  }
  .frontMerchantTitle {
    margin-top: 20px;
  }
  .frontMerchantTitle h2 {
    font-size: 18px !important;
  }

  /* Section2 */
  .linearBox {
    width: 90%;
  }
  .linearBox img {
    width: 100px;
  }
  .frontLink a {
    font-size: 16px;
  }
  .textBox div {
    width: 40px;
    height: 40px;
  }
  .textBox p {
    font-size: 22px;
    line-height: 37px;
    color: #fff;
  }

  /* Section4 */
  .industrySection {
    margin: 10px;
  }
  .industrySection div {
    width: 100px;
    height: 100px;
  }

  /* Section5 */
  .comingSoon div {
    width: 80%;
  }
  .coming {
    width: 220px;
    top: -45px;
    left: 25px;
  }
  .soon {
    width: 220px;
    bottom: -50px;
    right: 20px;
  }
  .comingSoonContent {
    margin: 10px 2em 0;
  }
}