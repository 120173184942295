@import "src/css/variables.scss";

/* Section1 */
.frontAboutSection1 {
  background: linear-gradient(103.93deg, #fff 0%, #F4F4F4 46.35%, #F6F6F6 100%);
}
.frontAboutSection1 .frontAboutIntro {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.frontAboutIntro .wrapper {
  display: flex;
  gap: 20px;
  margin-bottom: 50px;
}
.frontAboutIntro .wrapper div {
  width: 60px;
  height: 70px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 10px 10px 0px var(--front-title), 0px 2px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}
.frontAboutIntro .wrapper div p {
  color: var(--front-title);
  font-size: 40px;
  font-weight: bold;
  margin: 0;
}

/* Section2 */
.frontCard .ant-statistic-title {
  font-size: 16px;
  color: var(--front-primary);
}
h4.front.about {
  margin: 20px 15em 0;
  font-weight: normal;
  line-height: 35px;
}

/* Section3 */
.coreBox div {
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.coreBox img {
  width: 200px;
}

/* Section4 */
.frontAboutSection4 {
  background: linear-gradient(99.25deg, #F4F4F4 1.91%, #FFFFFF 51.98%, #FBFBFB 100%);
}
.frontAboutSection4 .ant-col {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  padding-left: 100px;
}
.frontAboutSection4 img {
  width: 50px;
  margin-right: 10px;
}
.frontAboutSection4 h3 {
  font-size: 20px !important;
}

@media (max-width: 1150px) {
  .frontAboutSection4 .ant-col {
    padding-left: 50px;
  }
}

@media (max-width: 900px) {
  /* Section1 */
  .frontAboutIntro .wrapper {
    margin-bottom: 0;
  }

  /* Section2 */
  h4.front.about {
    margin: 20px 5em 0;
  }

  /* Section3 */
  .coreBox img {
    width: 150px;
  }

  /* Section4 */
  .frontAboutSection4 .about {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  /* Section2 */
  h4.front.about {
    margin: 20px 2em 0;
  }

  /* Section3 */
  .coreBox div {
    height: 150px;
  }
  .coreBox img {
    width: 150px;
  }
  
  /* Section4 */
  .frontAboutSection4 .ant-col {
    padding-left: 0;
  }
  .frontAboutSection4 img {
    width: 30px;
    margin-right: 10px;
  }
  .frontAboutSection4 .about {
    font-size: 16px;
  }
}