.menu {
  height: 100%;
  min-width: 200px; 
  background-color: #324057;
  padding-left: 0px !important;
  padding-right: 0px !important;
  position: fixed;
  top: 0px;
  z-index: 999;
}

.menu .menuIcon {
  padding-right: 10px;
  margin-bottom: 4px;
}

.menu .logoImg {
  width: 80px;
  margin: 1em 4em;
}

.menu a {
  text-decoration: none;
}

/* cover Ant Design default style */
.menu .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: #324057;
}

.menu .ant-menu-item {
  margin: 0px !important;
  height: 45px !important;
  color: #fff;
}

.menu .ant-menu-item-selected,
.menu .ant-menu-dark .ant-menu-item-selected .anticon,
.menu .ant-menu-dark .ant-menu-item-selected .anticon + span,
.menu .ant-menu-dark .ant-menu-item:hover {
  background-color: #fff !important;
  color: #324057 !important;
}