@import "src/css/variables.scss";

/* Section1 */
.frontJetDaiSection1 {
  background: url("src/assets/jetdai/common/background.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  min-height: 650px;
}
.frontJetDaiTitle {
  margin-top: 50px;
  padding-left: 6em;
}
.frontJetDaiTitle .imgTitle {
  display: flex;
  justify-content: flex-start;
  align-items: end;
}
.frontJetDaiTitle .imgTitle .logo {
  width: 230px;
}
.frontJetDaiTitle .imgTitle .badge {
  width: 100px;
  margin-left: 8px;
}
.frontJetDaiTitle .intro .front {
  margin-top: 1em;
  color: var(--front-title);
}

/* Section2 */
.frontJetDaiResolution,
.frontJetDaiResolution .image {
  width: 90%;
}
.frontJetDaiResolution .highlight-first-text {
  font-size: 22px;
  font-weight: bold;
}
.contentBox {
  margin-top: 12px;
}
.contentBox h3 {
  font-weight: bold;
}
.contentBox p {
  margin: 0 2em;
}

/* Section3 */
.frontJetDaiProject {
  border-radius: 30px;
  min-height: 100%;
  padding: 16px;
}
.frontJetDaiProject.red {
  background: url("src/assets/jetdai/common/div_bg_01.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.frontJetDaiProject.red .frontBtn-transparent:hover {
  background-color: white;
  color: var(--front-red);
}
.frontJetDaiProject.orange {
  background: url("src/assets/jetdai/common/div_bg_02.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.frontJetDaiProject.orange .frontBtn-transparent:hover {
  background-color: white;
  color: var(--front-orange);
}
.frontJetDaiProject.blue {
  background: url("src/assets/jetdai/common/div_bg_03.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.frontJetDaiProject.blue .frontBtn-transparent:hover {
  background-color: white;
  color: var(--front-blue);
}
.frontJetDaiProject.green {
  background: url("src/assets/jetdai/common/div_bg_04.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.frontJetDaiProject.green .frontBtn-transparent:hover {
  background-color: white;
  color: var(--front-green);
}
.projectDetail {
  text-align: left;
  width: 100%;
  font-size: 16px;
}
.projectDetail .quote {
  border-left: 0.2em solid var(--front-grey);
  padding-left: 10px;
  margin-top: 2px;
}
.expire-warning-text {
  font-size: 14px !important;
}

/* Section4 */
.frontApplyBox img {
  width: 80%;
}
.frontApplyContent h4 {
  width: 80%;
}

/* JetDai FAQ */
.jetdai-faq-big-title {
  margin-top: 50px;
}
.jetdai-faq-divider {
	width: 80%;
	height: 2px;
  border-top: 1px dashed #8c8b8b;
}
.jetdai-faq-title {
  width: 100%;
  padding: 0 1em;
  margin-bottom: 20px;
  border-left: 6px var(--front-orange) solid;
  background-color: var(--front-light-yellow);
}
.jetdai-faq-content .list-title {
  margin-bottom: 20px;
}
.jetdai-faq-content .list-title:not(:first-child) {
  margin-top: 50px;
}
.apply-step img {
  width: 200px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 850px) {
  /* Section1 */
  .frontJetDaiSection1 {
    background-size: contain;
    min-height: 500px;
  }
  .frontJetDaiTitle {
    padding-left: 2em;
  }

  /* Section2 */
  .contentBox p {
    margin: 0 1em;
  }
}

@media (max-width: 600px) {
  /* Section1 */
  .frontJetDaiSection1 {
    margin-top: 30px;
    min-height: 350px;
  }
  .frontJetDaiTitle {
    margin-top: 0;
    padding-left: 1em;
  }
  .frontJetDaiTitle .imgTitle .logo {
    width: 200px;
  }
  .frontJetDaiTitle .imgTitle .badge {
    width: 80px;
  }
  .frontJetDaiTitle .title {
    min-height: 80px;
  }
  .frontJetDaiTitle .intro {
    font-size: 18px;
  }

  /* JetDai FAQ */
  .jetdai-faq-big-title {
    margin-top: 30px;
  }
}
