.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.swiper {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide {
  background-size: contain;
}
.main {
  width: 290px;
  height: 290px;
  border-radius: 10px;
}
.thumb {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0 0;
}
.thumb .swiper-slide {
  width: 25%;
  height: 100%;
  /* opacity: 0.4; */
}
.thumb .swiper-slide-thumb-active {
  /* opacity: 1; */
  border-radius: 13px;
}
.swiper-slide img {
  border-radius: 10px;
  display: block;
  cursor: pointer;
}
@media (max-width: 600px) {
  .main {
    width: 200px;
    height: 200px;
  }
}