.welcome {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    position: relative;
    background-image: url("src/assets/welcome_left.png"), url("src/assets/welcome_right.png");
    background-repeat: no-repeat, no-repeat;
    background-color: #f0f0f0;
    background-position: 0 100%, 100% 0;
}
.welcomeSvg {
    transform: scale(1);
    min-width: 524px;
    display: block;
    margin: 30px;
}
#welcome {
    background-image: url("src/assets/welcome_sprite.svg");
}
.shapeshifter.play {
    -webkit-animation-name: welcome;
    animation-name: welcome;
}
.shapeshifter {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: steps(60);
    animation-timing-function: steps(60);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
    width: 524px;
    height: 89px;
    background-repeat: no-repeat;
}

@keyframes welcome {
    0% {
      background-position: 0px 0px;
    }
    100% {
      background-position: -31440px 0px;
    }
}
.shapeshifter {
    animation-duration: 3000ms;
    animation-timing-function: steps(60);
    animation-fill-mode: forwards;
    animation-delay: .3s;
    width: 524px;
    height: 89px;
    background-repeat: no-repeat;
}
.shapeshifter.play {
    animation-name: welcome;
}
  
@media (max-width: 768px) {
    .welcomeSvg {
      transform: scale(0.9);
    }
}
@media (max-width: 576px) {
    .welcomeSvg {
      transform: scale(0.6);
    }
}