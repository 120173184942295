.adminBase {
  padding: 0px !important;
  height: 100%;
}

.noHeader {
  background-color: #fff;
  text-align: left;
  width: 100%;
  height: auto;
}

.webContent {
  overflow: hidden;
  margin-top: 60px;
  margin-bottom: 40px;
  padding-left: 200px;
}

.frontContent {
  min-height: auto;
  background-color: #fff;
}

.mobileContent {
  overflow: hidden;
  margin-top: 60px;
  margin-bottom: 40px;
}

.default {
  color: #324057;
}

.title {
  margin-top: 20px;
  margin-bottom: 5px;
  color: #324057;
  font-size: 16px;
}

.subTitle {
  color: #8492a6 !important;
}

.danger {
  color: #ff4949;
}

.required {
  color: #a94442;
}

.ant-form-item-no-colon,
.ant-form-item-label label {
  color: #324057 !important;
}
.ant-modal-body {
  padding: 0;
}

/* merchant layout */
.merLayout {
  height: 100%;
  text-align: left;
  overflow: scroll;
}
.merLayout .merLayoutCard {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;
  padding: 0;
  margin-bottom: 20px;
}
.merLayout .ant-card-body {
  padding: 10px 15px 30px 15px;
}

/* text editor */
.rdw-editor-main {
  background: white;
}