@import "src/css/variables.scss";

/* Section1-1 */
.frontGuideSection1, .frontActivitySection1 {
  background: url("src/assets/guide/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.frontGuideIntro {
  margin-top: 50px;
  padding-left: 4em;
}
.frontGuideIntro .title {
  background: url("src/assets/guide/icon_text.png");
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  min-height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.frontGuideIntro h1 {
  margin-left: 16px;
  margin-bottom: 0;
}
.frontGuideIntro .intro {
  margin-top: 1em;
}
.frontGuideIntro .intro h2 {
  font-size: 24px;
  font-weight: 450;
  margin: 0;
  color: var(--front-grey);
}
.stepPrefix {
  width: 16px;
  margin-right: 10px;
  margin-top: 10px;
}
.stepTitle {
  font-size: 18px;
}
.stepTitle.red {
  color: var(--front-required);
}
.stepTitle
.stepContent {
  font-size: 16px;
  padding: 10px 0 3em;
}
.stepContent p {
  margin-bottom: 6px;
}
.stepIcon img {
  width: 60px;
  margin: 0 20px 0 10px;
}
.stepSwiperSection {
  margin-top: 50px;
}
.stepSwiper {
  width: 250px !important;
  height: 500px;
  background: linear-gradient(67.87deg, #F7F7F7 12.63%, #EBEBEB 86.52%);
  box-shadow: -10px 7px 6px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.stepSwiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.stepSwiper img {
  width: 200px !important;
  height: 433px !important;
  border-radius: 20px !important;
}
.stepSwiper .swiper-pagination-bullet-active {
  background-color: var(--front-primary);
}

/* Section2 */
.squareLinear {
  width: 80%;
  background: linear-gradient(148.93deg, #fff 18.88%, #F4F4F4 47.81%, #E0E0E0 81.28%);
  border: 10px solid #fff;
  box-shadow: 0px 0px 0px 2px var(--front-grey-03);
  border-radius: 5px;
}
.squareLinear img {
  width: 60%;
  margin: 1em;
}
.contentBox {
  margin-top: 16px;
}
.contentBox h3 {
  font-weight: bold;
}
.contentBox .front.red {
  color: var(--front-red);
}
.contentBox .front.orange {
  color: var(--front-orange);
}
.contentBox .front.blue {
  color: var(--front-blue);
}
.contentBox .front.green {
  color: var(--front-green);
}
.contentBox h4 {
  margin: 0 2em;
  font-size: 18px !important;
}

/* Section3 */
.diamond {
  width: 180px;
  height: 180px;
  border-radius: 30px;
  transform: rotate(45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3em 50px;
}
.diamond h4 {
  width: 180px;
  color: #fff;
  display: inline-block;
  transform: rotate(-45deg);
  text-align: center;
  margin: 0 1em;
}
.diamond.red {
  background-color: var(--front-red);
}
.diamond.orange {
  background-color: var(--front-orange);
}
.diamond.blue {
  background-color: var(--front-blue);
}
.diamond.green {
  background-color: var(--front-green);
}

/* Section4 */
.userFeedback {
  text-align: left;
}

/* Installment FAQ */
.ins-faq-title {
  width: 100%;
  padding: 0 1em;
  margin-bottom: 20px;
  border-left: 6px var(--front-orange) solid;
  background-color: var(--front-light-yellow);
}

.speech-box-qu, .speech-box-ans {
  display: flex;
  justify-content: start;
  align-items: center;
}
.speech-box-qu {
  margin-bottom: 10px;
}
.speech-box-ans {
  margin-bottom: 20px;
}
.speech-avatar-qu, .speech-avatar-ans {
  min-width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100%;
  text-align: center;
  margin-right: 30px;
}
.speech-avatar-qu {
  background: #F9AABD;
}
.speech-avatar-ans {
  background: #96C8E1;
}
.speech-bubble-qu, .speech-bubble-ans {
	position: relative;
	border-radius: 1em;
  width: fit-content;
  padding: .7em;
}
.speech-bubble-qu::after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-left: 0;
	border-top: 0;
	margin-top: -10.5px;
	margin-left: -20px;
  border-right-color: #FFE3EA;
}
.speech-bubble-ans::after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-left: 0;
	border-top: 0;
	margin-top: -10.5px;
	margin-left: -20px;
  border-right-color: #DDF4FF;
}
.speech-bubble-qu {
  background: #FFE3EA;
}
.speech-bubble-ans {
  background: #DDF4FF;
}

.step-box {
  width: 80%;
  min-height: 60px;
  padding: .5em;
  /* margin-bottom: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px var(--front-orange) solid;
  border-radius: 15px;
}
.arrow-right {
  border: solid black;
  border-width: 0px 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  margin-left: 10px;
}
.step-img {
  width: 45%;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* table */
.ins-table {
  margin-top: 30px;
  border-collapse: separate;
  width: 100%;
  border-spacing: 0;
  border: 1px var(--front-primary) solid;
  text-align: center;
  border-radius: 15px;
}
.ins-table th {
  background-color: var(--front-primary);
  color: #fff;
  padding: .5em 0;
}
.ins-table td {
  padding: .3em 1em;
  text-align: left;
  border-top: 1px var(--front-primary) dotted;
  border-left: 1px var(--front-primary) dotted;
}
.ins-table tr td:first-child {
  border-left: none;
  text-align: center;
}
.ins-table th {
  border-top: 1px dotted #fff;
  border-left: 1px dotted #fff;
}
.ins-table th:first-child {
  border-radius: 10px 0 0 0;
}
.ins-table th:last-child {
  border-radius: 0 10px 0 0;
}
.ins-table tr:nth-of-type(1) th {
    border-top: none;
}
.ins-table tr:first-child th:first-child {
  border-top: none;
  border-left: none;
}
.ins-table tr:last-of-type {
  border-bottom: 2px var(--front-primary) solid;
}

@media (max-width: 1150px) {
  .diamond {
    width: 160px;
    height: 160px;
    border-radius: 30px;
    transform: rotate(45deg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em;
  }
}

@media (max-width: 850px) {
  /* Section2 */
  .contentBox h4 {
    margin: 0 1em;
    font-size: 16px !important;
  }
  
  /* Section3 */
  .diamond {
    width: 130px;
    height: 130px;
    margin: 6px;
  }
  .diamond h4 {
    width: 130px;
    font-size: 14px !important;
  }
  .step-img {
    width: 70%;
  }
}

@media (max-width: 767px) {
  /* Section1-1 */
  .stepSwiperSection {
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  /* Section1-1 */
  .frontGuideIntro {
    margin-top: 0;
    padding-left: 1em;
  }
  .frontGuideIntro .title {
    min-height: 80px;
  }
  .frontGuideIntro .intro h2 {
    font-size: 18px;
  }

  .step-img {
    width: 100%;
  }
}

/* Activity Page */
.jcoinBtn {
  border: none;
  background-color: transparent;
  margin-bottom: 50px;
}

/* Section1 */
.frontJcoinLogo {
  margin-top: 50px;
}
.frontJcoinLogo img, .jcoinBtn img {
  width: 50%;
}
.jcoinPageContent {
  width: 80%;
  height: fit-content;
}
.jcoinPageContent .image {
  width: 150px;
  height: 150px;
  display: flex;
  border-radius: 50%;
  border: solid 6px transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  justify-content: center;
  align-items: center;
}
.jcoinPageContent .image.red {
  background-image: linear-gradient(#f5f5f5, #f5f5f5), 
                    linear-gradient(135deg, rgba(255, 121, 123, 1), rgba(246, 192, 193, 0.5));
}
.jcoinPageContent .image.orange {
  background-image: linear-gradient(#f5f5f5, #f5f5f5), 
                    linear-gradient(135deg, rgba(250, 140, 22, 1), rgba(244, 200, 153, 0.5));
}
.jcoinPageContent .image.green {
  background-image: linear-gradient(#f5f5f5, #f5f5f5), 
                    linear-gradient(135deg, rgba(140, 198, 63, 1), rgba(200, 223, 169, 0.5));
}
.jcoinPageContent .image.blue {
  background-image: linear-gradient(#f5f5f5, #f5f5f5), 
                    linear-gradient(135deg, rgba(0, 145, 206, 1), rgba(144, 202, 226, 0.5));
}
.jcoinPageContent .image img {
  width: 80px;
}

/* Section2 */
.jcoinGiftGroup {
  width: 80%;
  display: flex;
  flex-flow: row;
  overflow-x: auto;
}
.jcoinGiftList {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.jcoinGiftCard {
  width: 60%;
}
.jcoinGiftImg {
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.jcoinGiftImg .jcoinNum {
  position: absolute;
  font-weight: bold;
  top: 8px;
  left: -4px;
  color: white;
  background-color: var(--front-orange);
  width: fit-content;
  padding: 6px 12px;
  text-align: center;
  border-radius: 0 20px 20px 0;
}
.jcoinGiftImg img {
  width: 100%;
}
.jcoinGiftCard .giftAmount {
  background-color: var(--front-red);
  color: white;
  padding: 4px 0;
  border-radius: 50px;
  margin: 6px 10px 0;
}

/* Section3 */
.jcoinStep {
  width: 80%;
  height: fit-content;
}
.jcoinStep .step {
  width: 120px;
  height: 120px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 70px;
}
.jcoinStep .step.red {
  background-color: var(--front-red);
}
.jcoinStep .step.orange {
  background-color: var(--front-orange);
}
.jcoinStep .step.green {
  background-color: var(--front-green);
}
.jcoinStep .step.blue {
  background-color: var(--front-blue);
}

@media (max-width: 767px) {
  .jcoinBtn img {
    width: 100%;
  }
  /* Section1 */
  .frontJcoinLogo img {
    width: 70%;
  }
  .jcoinPageContent .image {
    width: 100px;
    height: 100px;
  }
  .jcoinPageContent .image img {
    width: 50px;
  }

  /* Section2 */
  .jcoinGiftList {
    flex: 0 0 60%;
  }
  .jcoinGiftCard {
    width: 90%;
  }

  /* Section3 */
  .jcoinStep .step {
    width: 100px;
    height: 100px;
    font-size: 50px;
  }
}