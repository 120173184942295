@import "src/css/variables.scss";

.frontActivityShareMain {
  /* background-image: url("src/assets/jetdai/activity-share/bg.png"); */
  background: linear-gradient(135deg,  #fab870 0%,#f9cc91 51%,#f9cc91 51%,#fe817d 100%);
  background-repeat: no-repeat;
  background-position: top left center;
  background-size: 100%;
  width: 100%;
  height: 100%;
}

.frontActivityShareMainStart {
  background-image: url("src/assets/jetdai/activity-share/start.png");
  background-repeat: no-repeat;
  background-position: top left center;
  background-size: 100%;
  width: 100%;
  height: 100%;
}

.frontActivityShareTextStyle {
  font-size: 24px;
  color: #4d4d4d;
  font-weight: 700;
  position: relative;
  z-index: 0;
  width: 100%;
  letter-spacing: 5px;
}

.frontActivityShareFontStyle {
  font-size: 32px;
}

.frontActivityShareTextStyle::before,
.frontActivityShareTextStyle::after
 {
  content: attr(data-stroke);
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
}
.frontActivityShareTextStyle::after {
  -webkit-text-stroke: 4px #fff;
  text-stroke: 4px #fff;
  /* text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px -2px 4px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.2), 4px 2px 4px rgba(0, 0, 0, 0.2); */
}

/* linear-gradient(142deg, #910106 10.24%, #DC0105 29.53%, #920106 52.18%) */
/* Permalink - use to edit and share this gradient. 固定鏈接 - 用於編輯和分享此漸變： https://colorzilla.com/gradient-editor/#fab870+0,f9cc91+51,f9cc91+51,fe817d+100 */
.isShowMobile {
  display: none;
}
.isHideMobile {
  display: inline-block;
}

.frontLandingBtn-dark-orange {
  border: 2px solid #FA8C16;
  font-size: 18px;
}

/* Section1 */
.activityShare1 {
  width: 100%;
}

.activityShare2 {
  margin-top: 40px;
  width: 600px;
  height: 40px;
}

.activityShare3 {
  max-width: 400px;
  max-height: 160px;
  width: auto;
  height: auto;
}

.activityShareTitle {
  max-width: 230px;
  max-height: 60px;
  width: auto;
  height: auto;
}

.activityShareBox {
  border-radius: 10px;
  height: auto;
  font-size: 20px;
  text-align: left;
  padding: 10px 0 10px 15px;
}

.activityShareBoxTitle {
  letter-spacing: 2px;
  padding: 5px;
}

/* section2 */
.activitySharePeople {
  position: inherit;
  max-width: 750px;
  width: auto;
  margin: 0 auto;
}

.activitySharePeople img {
  position: absolute;
  width: 140px;
  height: 190px;
  top: 9px;
  left: -123px;
}

.activityShareMain {
  position: inherit;
  z-index: 1;
  max-width: 750px;
  height: auto;
  background-color: #fffbfbcf;
  border-radius: 10px;
  width: auto;
  height: auto;
  font-size: 18px;
  letter-spacing: 1px;
  padding: 10px 0;
  margin: 0 auto 20px auto;
  font-weight: 500;
}

.activityShareMain img {
  width: 30px;
  height: 30px;
  margin-top: -5px;
  margin-right: 3px;
}

.linePointGreen {
  color: var(--front-line-green);
}

/* section4 */
.activityShareBoxOl {
  padding-left: 45px;
}

.activityShareBoxLi {
  padding: 0px 5px 3px 0;
}

.activityShareBoxUlStyle {
  list-style: disc;
  padding-left: 30px;
}

@media (max-width: 768px) {
  .frontActivityShareTextStyle {
    font-size: 20px;
  }
  .activitySharePeople, .activityShareMain {
    max-width: 550px;
  }
  .activityShareMain {
    font-size: 16px;
  }
  .activitySharePeople img {
    left: -100px;
  }
}


@media (max-width: 600px) {
  .frontActivityShareMainStart {
    background-image: url("src/assets/jetdai/activity-share/start_mobile.png");
    background-repeat: no-repeat;
    background-position: top left center;
    background-size: 100%;
    width: 100%;
    height: 100%;
  }

  .frontActivityShareTextStyle {
    font-size: 16px;
  }
  
  .frontActivityShareFontStyle {
    font-size: 18px;
  }

  .activitySharePeople img {
    width: 120px;
    height: 170px;
    top: 86px;
    left: 0px;
  }

  /* .activityShare1 {
    margin-top: 30px;
    height: 50px;
  } */
  
  .activityShare2 {
    margin-top: 25px;
    width: 300px;
    height: 20px;
  }
  
  .activityShare3 {
    width: 320px;
    height: 120px;
  }

  .activityShareTitle {
    width: 150px;
    height: 48px;
  }

  .activityShareBox {
    width: 325px;
    font-size: 14px;
    /* padding: 8px 0 8px 6px; */
  }

  .activityShareBoxTitle {
    padding: 0;
  }

  /* section2 */
  /* .activitySharePeople {
    position: relation;
  }

  .activitySharePeople img {
    position: absolute;
    width: 90px;
    height: 130px;
    top: 75px;
    left: 0;
  } */

  /* section4 */
  /* .activityShareBoxOl {
    padding-left: 45px;
  } */
  .isShowMobile {
    display: block;
  }
  .isHideMobile {
    display: none;
  }

  .activityShareMain {
    max-width: 350px;
  }

  .activityShareBoxUl {
    width: 325px;
    font-size: 14px;
    padding: 8px 0 8px 32px;
  }

  .activityShareMain {
    font-size: 16px;
  }

  .activityShareMain img {
    width: 25px;
    height: 25px;
  }
}