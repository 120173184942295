.loginContainer {
    height: 100vh;
    background-color: #324057;
    overflow: hidden;
}
.loginRow {
    text-align: center;
    height: 100vh;
}
.loginLogoImg {
    width: 150px;
    margin-bottom: 20px;
}
.loginText {
    color: #324057;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 20px;
}
.loginBtn, .loginBtn:hover, .loginBtn:focus {
    background-color: #324057;
    color: white;
    border: #324057;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.loginFormItem {
    text-align: left;
}