/* Login css */
.loginContainer {
  height: 100vh;
  background-color: #324057;
  overflow: hidden;
}

.loginContainer .row {
  text-align: center;
  height: 100vh;
}

.loginContainer .logoImg {
  width: 150px;
  margin-bottom: 20px;
}

.loginContainer .text {
  color: #324057;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 20px;
}

.loginContainer .bgDefault {
  background-color: #324057;
}

.loginBtn {
  color: #fff;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.loginContainer .inputItem {
  text-align: left;
}

.loginContainer .ant-input-prefix {
  padding-right: 5px;
  font-size: 16px;
}