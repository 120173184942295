.footer {
  background-color: #fff;
  color: #324057;
  height: 30px;
  width: 100%;
  font-size: 14px;
  text-align: right;
  padding: 5px 15px;
  position: fixed;
  bottom: 0;
  right: 0;
}